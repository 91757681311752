import { useEffect, useRef } from 'react';
/**
 * @param {number} callback - callback to pass in on scroll
 * @return
 */
const useWindowScroll = (callback) => {
    const timeout = useRef();
    useEffect(() => {
        const windowScroll = () => {
            timeout.current = setTimeout(() => {
                callback(window.scrollX, window.scrollY);
            }, 10);

        };
        window.addEventListener('scroll', windowScroll);
        /** on unmount clean up the timeout and the listener */
        return () => {
            if (timeout.current) { clearTimeout(timeout.current); }
            window.removeEventListener('scroll', windowScroll);
        };
    }, []);
};
export default useWindowScroll;
