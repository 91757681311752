import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import ReactCssSizes from 'react-css-sizes';
import { withPrefix, StaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import Icons from './icons';
import '../scss/ui.scss';

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={(data) => (
            <>
                <Helmet
                    title="Element Studio - Cheltenham-based Web Design and Development Studio"
                    meta={[
                        {
                            name: 'description',
                            content:
                                'Element Studio is a Web Design and Development agency based in Cheltenham, specialising in bespoke web applications built in Laravel PHP and React JS.'
                        }
                    ]}
                >
                    <html lang="en" />
                    <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('apple-touch-icon.png')} />
                    <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('favicon-32x32.png')} />
                    <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('favicon-16x16.png')} />
                    <link rel="manifest" href={withPrefix('site.webmanifest')} />
                    <link rel="mask-icon" href={withPrefix('safari-pinned-tab.svg')} color="#5bbad5" />
                    <meta name="msapplication-TileColor" content="#da532c" />
                    <meta name="theme-color" content="#ffffff" />

                    <meta property="og:image" content='http://builtbyelement.io/images/og-image.jpg' />
                    <meta property="og:image:width" content="279" />
                    <meta property="og:image:height" content="279" />
                    <meta
                        property="og:description"
                        content="A small, nimble, full-stack Web Design &amp; Development studio based in Cheltenham, UK"
                    />
                    <meta property="og:title" content="Element Studio" />
                    <meta property="og:url" content="http://builtbyelement.io" />

                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@builtbyelement" />
                    <meta name="twitter:description" content="A small, nimble, full-stack Web Design &amp; Development studio based in Cheltenham, UK"></meta>
                    <meta name="twitter:image" content='http://builtbyelement.io/images/og-image.jpg' />
                    <style>{`
            @font-face {
              font-family: 'againts';
              src: url(${withPrefix('fonts/Againts.woff2')}) format('woff2'),
                url(${withPrefix('fonts/Againts.woff')}) format('woff');
              font-weight: normal;
              font-style: normal;
            }
          `}</style>
          <script>
            {`
(function (m, a, z, e) {
  var s, t;
  try {
    t = m.sessionStorage.getItem('maze-us');
  } catch (err) {}

  if (!t) {
    t = new Date().getTime();
    try {
      m.sessionStorage.setItem('maze-us', t);
    } catch (err) {}
  }

  s = a.createElement('script');
  s.src = z + '?apiKey=' + e;
  s.async = true;
  a.getElementsByTagName('head')[0].appendChild(s);
  m.mazeUniversalSnippetApiKey = e;
})(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '015182b4-8d03-42b7-be80-24cd71d8e97a');
`}
          </script>
                </Helmet>

                <ReactCssSizes />
                <Icons />
                <Header siteTitle={data.site.siteMetadata.title} />
                <main className="main">{children}</main>
                <Footer />
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
