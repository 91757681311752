import React from 'react';
import { withPrefix } from 'gatsby';

const Footer = ({ siteTitle }) => (
    <footer className="footer wrapper-chunky">
        <div className="container-medium">
            <div className="grid-x grid-margin-x small-only-rhythm">
                <div className="cell medium-3 footer-logo-wrap">
                    <img src={withPrefix('images/logo-e-square.svg')} className="footer-logo" alt={`${siteTitle} small logo`} />
                </div>
                <div className="cell medium-3">
                    <h4 className="type-label type-small-heading">
                        HQ<span className="type-highlight">&nbsp;//</span>
                    </h4>
                    <a
                        href="https://www.google.com/maps/place/Element+Studio/@51.8969685,-2.0769041,15z/data=!4m2!3m1!1s0x0:0xf1de554285b621b3?sa=X&ved=2ahUKEwifrK3e563uAhUkWhUIHb5KBlgQ_BIwC3oECBwQBQ"
                        target="_blank"
                        rel="nofollow  noopener noreferrer"
                        title="View address on map"
                    >
                        <address>
                            16 Imperial Square,
                            <br />
                            Cheltenham,
                            <br />
                            Gloucestershire.
                            <br />
                            GL50 1QZ
                        </address>
                    </a>
                </div>
                <div className="cell medium-3">
                    <h4 className="type-label type-small-heading">
                        Tel<span className="type-highlight">&nbsp;//</span>
                    </h4>
                    <a href="tel:01242651111" className="type-textlink" title="Tel: 01242 651111">
                        01242 651111
                    </a>
                    <h4 className="type-label type-small-heading">
                        Email<span className="type-highlight">&nbsp;//</span>
                    </h4>
                    <a href="mailto:howdy@builtbyelement.io" className="type-textlink" title="Email: howdy@builtbyelement.io">
                        howdy@builtbyelement.io
                    </a>
                </div>
                <div className="cell medium-3">
                    <h4 className="type-label type-small-heading">
                        Social<span className="type-highlight">&nbsp;//</span>
                    </h4>
                    <a
                        href="https://twitter.com/builtbyelement"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        title="Element Studio on Twitter"
                        className="text-label--grey footer-social-link"
                    >
                        Twitter
                    </a>
                    <a
                        href="https://www.instagram.com/builtbyelementstudio/"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        title="Element Studio on Instagram"
                        className="text-label--grey footer-social-link"
                    >
                        Instagram
                    </a>
                </div>
                <div className="cell small-12 text-align--center">
                    <hr className="sep-short-dark" />
                    <small className="type-colophon">All content copyright Element Studio Ltd {new Date().getFullYear()}</small>
                    <br />
                    <small className="type-colophon">
                        <a href="/privacy">Privacy Policy</a> | Company reg no: 09205638
                    </small>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
